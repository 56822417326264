import {NavLink} from 'react-router-dom';

export default function Footer() {
    return (
        <>
            <footer className="mt-16">
                <div className="dec-section justify-around md:justify-between">
                    <div>
                        <h4 className="font-bold text-lg">Focus Area</h4>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Entertainment</NavLink
                            >
                        </p>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Education</NavLink
                            >
                        </p>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Sports</NavLink
                            >
                        </p>
                    </div>
                    <div>
                        <h4 className="font-bold text-lg">Links</h4>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Become A Volunteer</NavLink
                            >
                        </p>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Projects</NavLink
                            >
                        </p>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Teams</NavLink
                            >
                        </p>
                    </div>
                    <div>
                        <h4 className="font-bold text-lg">Social Links</h4>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Twitter</NavLink
                            >
                        </p>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Facebook</NavLink
                            >
                        </p>
                        <p className="py-4">
                            <NavLink to="" className="text-gray-700 hover:text-blue-500 focus:text-blue-500"
                            >Instagram</NavLink
                            >
                        </p>
                    </div>
                </div>
                <div className="text-center font-bold py-4">
                    Copyright © 2021 Fafydev - All Rights Reserved
                </div>
                <div></div>
            </footer>
        </>
    );
}
import Header from "../components/Header";
import { NavLink } from "react-router-dom";

function Contact() {
    return (
        <>
            <Header />
            <section class="dec-section">
                <div class="md:w-1/2 dec-section-inner">
                    <article class="prose">
                        <h2>Contact Us</h2>
                        <p>The Seal, KM 34, Lekki-Epe Expressway, Kajola bustop, Ibeju-Lekki, Lagos</p>
                        <p>+234 (706) 736 8542</p>
                        <p>info@fafydev.com</p>
                        <p class="border-t pt-2 font-bold">Seeking Others to participate, Contribute to our cause by clicking the donate button below</p>
                    </article>
                    <NavLink to="/donate" class="dec-btn uppercase">Donate Now</NavLink>
                </div>
                <div class="md:w-1/2 bg-blue-100 dec-section-inner bg-opacity-30">
                    <form>
                        <label class="block mt-4 md:mt-8">
                            <span class="text-gray-700">Full name</span>
                            <input type="text" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Full Name" />
                        </label>
                        <label class="block mt-4 md:mt-8">
                            <span class="text-gray-700">Email address</span>
                            <input class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="E-mail" type="email" />
                        </label>
                        <label class="block mt-4 md:mt-8">
                            <span class="text-gray-700">Mobile <small class="text-gray-400">(optional)</small></span>
                            <input class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Mobile Number" type="tel" />
                        </label>
                        <label class="block mt-4 md:mt-8">
                            <span class="text-gray-700">Message</span>
                            <textarea class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Your message" rows="4"></textarea>
                        </label>
                        <button class="dec-btn">Send Message</button>
                    </form>
                </div>
            </section>
        </>
    );
}

export default Contact;

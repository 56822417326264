import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

class Header extends Component {
    constructor() {
        super();
        this.state = {
            'isOpen': false
        };
    };

    render() {
        const toggle = () => this.setState({ 'isOpen': !this.state.isOpen });
        return (
            <header className="sm:sticky top-0 left-0 dec-section justify-between py-4 my-px shadow font-primary bg-white z-30">
                <div>
                    <NavLink aria-current="page" className="pr-8 text-blue-400 hover:text-blue-500 text-xl active" to="/"> fafydev</NavLink >
                    <span className="hidden md:inline-block">
                        <NavLink className="px-6 hover:text-blue-400" to="/becomeavolunteer"> Become a volunteer</NavLink >
                        <NavLink className="px-6 hover:text-blue-400" to="/contact"> Contact Us</NavLink >
                    </span>
                </div>
                <div className="hidden lg:inline-block">
                    <NavLink aria-current="page" className="px-8 py-2 rounded border border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white active" to="/donate"> Donate</NavLink >
                </div>
                <div className="lg:hidden relative">
                    <span className="fixed bg-white right-4 sm:static cursor-pointer" onClick={toggle}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 text-blue-400 hover:text-blue-500 text-xl">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"> </path>
                        </svg>
                    </span>
                    <div onClick={toggle} className={this.state.isOpen === false ? "hidden" : "fixed left-0 sm:left-auto right-0 bg-white p-8 pl-24 shadow-lg top-0 bottom-0 overflow-scroll text-right"}>
                        <div className="flex flex-col items-end">
                            <span className="w-8 absolute left-8 cursor-pointer">
                                <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="pr-8 text-blue-400 hover:text-blue-500 text-xl cursor-pointer">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"> </path>
                                </svg>
                            </span>
                            <NavLink aria-current="page" to="/" className="py-2 text-blue-400 hover:text-blue-500 text-xl active sm:hidden mt-8"> fafydev</NavLink >
                            <span className="flex flex-col md:hidden">
                                <NavLink className="py-2 hover:text-blue-400" to="/login"> Login</NavLink >
                                <NavLink className="py-2 hover:text-blue-400" to="/register"> Register</NavLink >
                                <NavLink className="py-2 hover:text-blue-400" to="/contact"> Contact Us</NavLink >
                            </span>
                            <NavLink className="py-2 hover:text-blue-400" to="/becomeavolunteer"> Become a volunteer</NavLink >
                            <NavLink aria-current="page" to="/donate" className="my-6 px-6 py-2 rounded border border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white active"> Donate</NavLink >
                        </div>
                    </div>
                </div>
            </header>
        );
    };
}

export default Header;
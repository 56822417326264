import Header from "../components/Header";
import SeatingImg from "../assets/img/sitting_view.png";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import CharlesImg from '../assets/img/charles.png';
import FisoyeImg from '../assets/img/fisoye.png';
import DolapoImg from '../assets/img/dolapo.png';
import IroroImg from '../assets/img/iroro.png';
import SportIcon from '../components/puzzle.js';
import EducationIcon from '../components/book.js';
import EntertainmentIcon from '../components/refresh.js';

function Index() {
    return (
        <>
            <Header />
            <section className="dec-section">
                <div className="w-1/2 dec-section-inner flex items-center">
                    <div>
                        <h2 className="text-3xl font-bold py-4">Education</h2>
                        <p className="py-4">
                            FafyDev is a lagos-based, non-profit organization dedicated to providing
                            mentorship, training and support to youths from underserved communities
                            in Nigeria
                        </p>
                        <NavLink to="/contact" className="dec-btn">Contact Us</NavLink>
                    </div>
                </div>
                <div className="w-1/2 dec-section-inner">
                    <img src={SeatingImg} alt="Seating students" />
                </div>
            </section>
            <section className="dec-section">
                <div className="w-1/2 dec-section-inner flex items-center">
                    <img src={SeatingImg} alt="Seating students" />
                </div>
                <div className="w-1/2 dec-section-inner">
                    <div>
                        <h2 className="text-xl font-bold py-4">Our Vision</h2>
                        <p className="py-4">
                            We envision a world where all Youths, hold the power to create
                            opportunity for themselves and others with a goal to eradicate poverty
                            in all it forms, while leveraging on partnerships for development.
                        </p>
                    </div>
                    <div className="mt-12">
                        <h2 className="text-xl font-bold py-4">Our Mission</h2>
                        <p className="py-4">
                            At fafyDev youths are introduced to a wide-range of skills and
                            experiences designed to help them achieve their life and career goals.
                            In addition to providing marketable life, business, and educational
                            skills, we also focus on teaching youths on how to best harness their
                            respective gifts and talent to best build a better Nigeria.
                        </p>
                    </div>
                </div>
            </section>
            <div className="dec-section"><div></div></div>
            <section className="dec-section bg-blue-400">
                <div className="w-full text-white">
                    <h2 className="text-3xl text-center font-bold">Focus Area</h2>
                    <div className="dec-section justify-around">
                        <div className="w-1/3 bg-blue-300 dec-card">
                            <div className="w-20 h-20 bg-blue-400 p-4"><SportIcon className="text-white"/></div>
                            <h3 className="text-xl font-bold mt-4">Sports</h3>
                            <p className="mt-4">
                                We are dedicated to use sports as a tool for development and peace.
                                Actors in sport, academia, government agencies...
                            </p>
                        </div>
                        <div className="w-1/3 bg-blue-300 dec-card mx-auto md:mx-8">
                            <div className="w-20 h-20 bg-blue-400 p-4"><EducationIcon className="text-white"/></div> 
                            <h3 className="text-xl font-bold mt-4">Education</h3>
                            <p className="mt-4">
                            We want to use educational process to improve awareness and understanding of the rapidly changing, independent and unequal world...
                            </p>
                        </div>
                        <div className="w-1/3 bg-blue-300 dec-card">
                            <div className="w-20 h-20 bg-blue-400 p-4"><EntertainmentIcon className="text-white"/></div>
                            <h3 className="text-xl font-bold mt-4">Entertainment</h3>
                            <p className="mt-4">
                            Entertainment is a form of activity that holds the attention and interest of audience. We intend to hunt for...
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dec-section">
                <div className="w-full">
                    <h2 className="text-3xl font-bold">Meet our Team</h2>
                    <div className="mt-8 dec-flex text-center">
                        <div className="md:w-1/4 my-4 md:my-auto">
                            <img src={FisoyeImg} alt="Fisoye Akinyemi" />
                            <p className="font-bold">Fisoye Akinyemi</p>
                            <p className="text-blue-700">Founder / CEO</p>
                        </div>
                        <div className="md:w-1/4 my-4 md:my-auto md:mx-8">
                            <img src={IroroImg} alt="Iroro Idogun" />
                            <p className="font-bold">Iroro Idogun</p>
                            <p className="text-blue-700">Members of BOT</p>
                        </div>
                        <div className="md:w-1/4 my-4 md:my-auto md:mx-8">
                            <img src={DolapoImg} alt="Dolapo Akinyemi" />
                            <p className="font-bold">Dolapo Akinyemi</p>
                            <p className="text-blue-700">Members of BOT</p>
                        </div>
                        <div className="md:w-1/4 my-4 md:my-auto">
                            <img src={CharlesImg} alt="Charles Egwuh" />
                            <p className="font-bold">Charles Egwuh</p>
                            <p className="text-blue-700">Projects Coordinator</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Index;

import { useState } from "react";
import Header from "../components/Header";

function Donate() {
    const [formDisplay, setFormDisplay] = useState(false);
    return (
        <>
            <Header />
            <section className="flex justify-center mt-4 md:mt-8">
                <div className="md:w-2/3 prose">
                    <h1>Join Our Cause</h1>
                    <div className="p-4 md:p-8 bg-green-200 flex hidden">
                        <div>Thank you. Your payment was successful. An email has been sent to you. We appreciate your support.</div>
                        <div className="p-4 cursor-pointer flex items-start">X</div>
                    </div>
                    <p>Please donate to a notable cause through any of the below means.</p>
                    <h2 className="border-b">Available Channels</h2>
                    <ul>
                        <li>
                            <h4>Bank Transfer</h4>
                            <div className="md:pl-8 grid grid-cols-1 md:grid-cols-2"><div>
                                <p className="font-bold">Access Bank</p>
                                <p>Fafydev International</p>
                                <p>00012188898</p>
                            </div>
                                <div>
                                    <p className="font-bold">Guaranty Trust Bank (GTB)</p>
                                    <p>Fafydev International</p>
                                    <p>00012188898</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h4>Online Payment</h4>
                            <p>You can pay using the secure options below</p>
                            <button onClick={() => setFormDisplay(!formDisplay)} className="dec-btn">PayStack</button>
                        </li>
                    </ul>
                </div>
                <div className={`${formDisplay === true ? "" : "hidden"} md:w-1/3 md:ml-8 bg-blue-400 bg-opacity-5`}>
                    <div className="dec-section-inner">
                        <p onClick={() => setFormDisplay(!formDisplay)} className="font-bold text-right mt-4 md:mt-8 cursor-pointer">X</p>
                        <p className="mt-4 md:mt-8">Please fill in your information below. This  is strictly for us confirming your payment and saying thank you.</p>
                        <form>
                            <label className="block mt-4 md:mt-8">
                                <span className="text-gray-700">Full name</span>
                                <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Your Fullname" />
                            </label>
                            <label className="block mt-4 md:mt-8">
                                <span className="text-gray-700">E-mail</span>
                                <input className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="email" placeholder="me@myemail.com" />
                            </label>
                            <label className="block mt-4 md:mt-8">
                                <span className="text-gray-700">Mobile Number</span>
                                <input className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="0909000000000000" type="tel" />
                            </label>
                            <div>
                                <label className="block mt-4 md:mt-8">
                                    <span className="text-gray-700">Amount</span>
                                    <input className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="5000" type="number" />
                                </label>
                                <small className="text-gray-500">(Amount is calculated in naira automatically, please enter the value only)</small>
                            </div>
                            <button className="dec-btn" type="submit">Pay Now</button>
                            <div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Donate;

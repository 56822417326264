import Header from "../components/Header";

function Register() {
    return (
        <>
            <Header />
        </>
    );
}

export default Register;

import Header from "../components/Header";

function Login() {
    return (
        <>
            <Header />
        </>
    );
}

export default Login;

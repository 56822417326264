import React from 'react';
import ReactDOM from 'react-dom';
import "./Index.css";
import Index from './views/Index';
import Contact from './views/Contact';
import Donate from './views/Donate';
import Register from './views/Register';
import Login from './views/Login';
import BecomeAVolunteer from './views/BecomeAVolunteer';
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import App from './views/App';

ReactDOM.render(
    <React.StrictMode>
        {/* <App /> */}
        <BrowserRouter>
            <Switch>
                <Route exact path="/"><Index /></Route>
                <Route path="/becomeavolunteer"><BecomeAVolunteer /></Route>
                <Route path="/login"><Login /></Route>
                <Route path="/register"><Register /></Route>
                <Route path="/donate"><Donate /></Route>
                <Route path="/contact"><Contact /></Route>
            </Switch>
        </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

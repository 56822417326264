import Header from "../components/Header";

function BecomeAVolunteer() {
    return (
        <>
            <Header />
            <section class="dec-section">
                <article class="md:w-1/2 dec-section-inner prose">
                    <h2>Volunteer</h2>
                    <p>Please, select a position (below) and fill the form displayed in the blue background to apply for a position.</p>
                    <h1 class="border-b pb-2">Available Positions</h1>
                    <p>Sorry, there are no positions available at the moment</p>
                </article>
                <div class="md:w-1/2 bg-blue-100 dec-section-inner bg-opacity-30">
                    <p>Please select a volunteer position on the left to view the position description and details.</p>
                </div>
            </section>
        </>
    );
}

export default BecomeAVolunteer;
